import { Box, Typography, IconButton, Popover, Button } from "@mui/material";

/* import { useState } from "react"; */
import { useTranslation } from "react-i18next";
/* import { useSelector } from "react-redux";
import { IReduxState } from "../../../redux/app_store";
import AppButton from "../../share/app-button"; */
import { Close as CloseIcon } from "../../icons/close";
import { apiRequestHandler } from "../../api/requestHandler";
import { IReduxState } from "../../redux/app_store";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
/* import { IUpdateUserRole, IUser } from "../../../model/user.model"; */

/* import UserManagementService from "../../../service/user-management.service";
import { useMutation } from "react-query";
import { serverErrorMessage } from "../../../utils/server-error-message";
import toast from "react-hot-toast";
import { EROLE } from "../../../enums/role.enum";
import { Selector } from "../../share/selector";
import router from "next/router"; */

export const ConfirmationAlertPopover = (props) => {
  const { selectedUnit, selectedOutlet } = useSelector(
    (store: IReduxState) => store
  );

  const { confirmationTo, setConfirmationTo } = props;
  const { t } = useTranslation();
  //const [confirmationTo, setConfirmationTo] = useState("");
  const router = useRouter();
  //@ts-ignore
  const handlerGoBack = () => {
    //setShowConfirmation(false);
    setConfirmationTo("");
    props.onClose();
  };
  const handlerResolveAlert = async () => {
    await apiRequestHandler(
      //`/api/hospitad/clouseau/deviations/outlets/${selectedOutlet?.id}/invoices/${props.dealDetails}/resolve`,
      `/api/general/price-alert/operation/article/${props.dealDetails?.id}`,
      "POST",
      {
        outletId: selectedOutlet?.id,
        article: props.dealDetails,
        operation: "resolve",
        startDate: selectedOutlet?.firstRecordAt,
        endDate: new Date().toISOString().split("T")[0],
      },
      {
        "x-unit-id": selectedUnit?.id,
        "Content-Type": "application/json",
      }
    );
    props.onClose();
    router.reload();
  };
  const handlerIgnoreAlert = async () => {
    await apiRequestHandler(
      `/api/general/price-alert/operation/article/${props.dealDetails?.id}`,
      "POST",
      {
        outletId: selectedOutlet?.id,
        article: props.dealDetails,
        operation: "ignore",
        startDate: selectedOutlet?.firstRecordAt,
        endDate: new Date().toISOString().split("T")[0],
      },
      {
        "x-unit-id": selectedUnit?.id,
        "Content-Type": "application/json",
      }
    );
    props.onClose();
    router.reload();
  };
  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box sx={{ padding: 2, maxWidth: "400px", minWidth: "300px" }}>
        <Box
          sx={{
            display: "flex",
            mb: 4,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ pr: 3 }}>
            {t("account_page__notifications__alerts__modals__resolve__title")}
          </Typography>
          <IconButton onClick={props.onClose}>
            <CloseIcon style={{ fontSize: "14px" }} />
          </IconButton>
        </Box>
        <Box>
          {confirmationTo === "resolve" ? (
            <>
              <Box sx={{ display: "flex" }}>
                <Typography>
                  {t(
                    "account_page__notifications__alerts__modals__resolve__messages__resolve_all_invoice"
                  )}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}
              >
                {/* <Button variant="text" onClick={handlerGoBack}>
                  {t(
                    "account_page__notifications__alerts__modals__resolve__back_button"
                  )}
                </Button> */}
                <Button variant="outlined" onClick={handlerResolveAlert}>
                  {t(
                    "account_page__notifications__alerts__modals__resolve__resolve_button"
                  )}
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ display: "flex" }}>
                <Typography>
                  {t(
                    "account_page__notifications__alerts__modals__resolve__messages__ignore_all_invoice"
                  )}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}
              >
                {/* <Button variant="text" onClick={handlerGoBack}>
                  {t(
                    "account_page__notifications__alerts__modals__resolve__back_button"
                  )}
                </Button> */}
                <Button variant="outlined" onClick={handlerIgnoreAlert}>
                  {t(
                    "account_page__notifications__alerts__modals__resolve__ignore_button"
                  )}
                </Button>
              </Box>
            </>
          )}
        </Box>

        {/* <Box display={"flex"} justifyContent="space-between" sx={{ mt: 2 }}>
          {userPermissions === "OWNER" ? (
            <AppButton
              sx={{ pr: 2, pl: 2, ml: 2, width: "fit-content", py: 1 }}
              onClick={() => {
                removeUserRoleMutation.mutate();
              }}
            >
              {t("delete")}
            </AppButton>
          ) : null}
          <AppButton
            sx={{ pr: 2, pl: 2, width: "fit-content", py: 1 }}
            variant="contained"
            onClick={() => {
              accessUserRoleMutation.mutate();
            }}
          >
            {t("save")}
          </AppButton>
        </Box> */}
      </Box>
    </Popover>
  );
};
