import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { useState, FC, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import config from "../../utils/config";
import InfoIcon from "@mui/icons-material/Info";

interface Props {
  name: string;
  hints?: string[];
  children?: React.ReactNode;
}
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "100%",
    fontSize: "1rem",
    border: "1px solid #dadde9",
  },
}));
const StagingFlag: FC<Props> = ({ children, name, hints }) => {
  const NEXT_PUBLIC_appEnv = config.appEnv;
  const [display, setDisplay] = useState<boolean>(false);
  const [hideFlag, setHideFlag] = useState<boolean>(
    localStorage.getItem("hideFlag") === "true" ? true : false
  );

  useEffect(() => {
    window.addEventListener("storage", () => {
      const flag = localStorage.getItem("hideFlag");
      setHideFlag(flag === "true" ? true : false);
    });
  }, []);

  return NEXT_PUBLIC_appEnv === "staging" && !hideFlag ? (
    <>
      <Accordion
        expanded={display}
        sx={{ border: "1px solid orange", borderRadius: "0.8rem", my: 2 }}
      >
        <AccordionSummary
          expandIcon={!!children && <ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            backgroundColor: "orange",
            borderRadius: 1,
            "& .MuiAccordionSummary-content": { alignItems: "center" },
          }}
          onClick={() => !!children && setDisplay(!display)}
        >
          {hints && hints.length > 0 && (
            <HtmlTooltip
              title={
                <>
                  <Typography variant="h6">Translation Keys:</Typography>
                  {hints?.map((hint, index) => (
                    <Typography key={index} variant="subtitle2">
                      {" "}
                      - {hint}
                    </Typography>
                  ))}
                </>
              }
            >
              <InfoIcon fontSize="large" sx={{ mr: 1 }} />
            </HtmlTooltip>
          )}
          <Typography variant="subtitle1">
            <strong>Staging Flag: {name}</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </>
  ) : null;
};

export default StagingFlag;
