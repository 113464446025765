import { useEffect, useState } from "react";

import { Box, Button, Container, Grid } from "@mui/material";
import type { NextPage } from "next";
import Head from "next/head";

import { subWeeks } from "date-fns";
import costumFormat from "date-fns/format";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { apiRequestHandler } from "../api/requestHandler";
import { DashboardLayout } from "../components/layout/dashboard-layout/dashboard-layout";
import { MainContent } from "../components/mainContent";
import { setActivityAction } from "../redux/action/activity";
import { setDateRangeAction } from "../redux/action/date-range";
import GuiderComponent from "../components/guider";
import config from "../utils/config";
import StagingFlag from "../components/staging-flag";
import SearchArticlesComponent from "../components/overview/overview-search-products";
import OverviewCostSummary from "../components/overview/overview-cost-summary";
import CostBreakdownWrapper from "./price-monitoring/overview-cost-component";
import OverviewPriceAlert from "../components/overview/overview-price-alert";

const Overview: NextPage = () => {
  const NEXT_PUBLIC_appEnv = config.appEnv;
  const { t } = useTranslation();
  const [selectedRange] = useState<"week" | "month" | "year">("week");
  const [startDate] = useState(new Date(subWeeks(Date.now(), 1)));
  const [endDate] = useState(new Date(Date.now()));
  const [isChangedRange] = useState<boolean>(false);

  const [stagingGuideTrigger, setStagingGuideTrigger] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const handleActivity = async () => {
    const data: any = await apiRequestHandler(
      `/api/activity?type=activity`,
      "GET",
      null
    );
    if (data) {
      dispatch(setActivityAction(data));
    }
  };
  useEffect(() => {
    //const data = fetch("/api/activity/1", { method: "GET" });

    handleActivity();
  }, []);

  useEffect(() => {
    if (isChangedRange) {
      const nextRange: Types.DateRange = {
        period: selectedRange,
        from: costumFormat(startDate, "yyyy-MM-dd"),
        to: costumFormat(endDate, "yyyy-MM-dd"),
      };
      dispatch(setDateRangeAction(nextRange));
    }
  }, [selectedRange]);

  return (
    <>
      <Head>
        <title>
          {t("dashboard")}: {t("overview")} | Parsly
        </title>
      </Head>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
        }}
      >
        <Container maxWidth="xl">
          {NEXT_PUBLIC_appEnv === "staging" ? (
            <StagingFlag name={`Welcome Guide`}>
              <GuiderComponent open={stagingGuideTrigger} />
              <Button
                variant="outlined"
                onClick={() => setStagingGuideTrigger(!stagingGuideTrigger)}
              >
                Open Guide
              </Button>
            </StagingFlag>
          ) : null}
          <MainContent />
          {/* <HowToSectionComponent /> */}
          <SearchArticlesComponent />
          <Grid container spacing={3} sx={{ justifyContent: "flex-start" }}>
            <Grid item md={4} xs={12}>
              <OverviewCostSummary />
            </Grid>
            <Grid item md={8} sm={12}>
              <CostBreakdownWrapper />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ justifyContent: "flex-start" }}>
            <Grid item xs={12}>
              <OverviewPriceAlert />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

Overview.getLayout = (page) => <DashboardLayout>{page}</DashboardLayout>;
export default Overview;
