import { useState } from "react";
import type { NextPage } from "next";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import CostBreakdownComponent from "./components/costBreakdown";

import { IInvoiceReport } from "../../model/report.model";
import { IReduxState } from "../../redux/app_store";
import { apiRequestHandler } from "../../api/requestHandler";
import { useRouter } from "next/router";
const todayDate = new Date().toISOString().split("T")[0];
const lastMonthDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth() - 1,
  new Date().getDate(),
  0,
  0,
  0,
  0
)
  .toISOString()
  .split("T")[0];
const CostBreakdownWrapper: NextPage = () => {
  //@ts-ignore
  const [invoiceReport, setInvoiceReport] = useState<IInvoiceReport>();
  const router = useRouter();
  const { selectedUnit, selectedOutlet } = useSelector(
    (store: IReduxState) => store
  );

  const SuppliersList: any = useQuery(
    ["SUPPLIERSLIST", selectedOutlet?.id, router],
    async () => {
      const data: any = await apiRequestHandler(
        `/api/suppliers`,
        "GET",
        {},
        {
          "x-unit-id": selectedUnit?.id,
          "Content-Type": "application/json",
        }
      );

      return data;
    },
    {
      enabled: !!selectedOutlet?.id,
    }
  );
  const SupplierReport: any = useQuery(
    ["REPORT", selectedOutlet?.id, router],
    async () => {
      const data: any = await apiRequestHandler(
        `/api/hospitad/financial/outlets/${selectedOutlet?.id}/suppliers-report?page=1&order=DESC&fromDate=${lastMonthDate}&toDate=${todayDate}&sort=totalSum&pageSize=25`,
        "GET",
        {},
        {
          "x-unit-id": selectedUnit?.id,
          "Content-Type": "application/json",
        }
      );

      return data;
    },
    {
      enabled: !!selectedOutlet?.id,
    }
  );

  const endlist = SupplierReport.data?.content?.map((supplier: any) => {
    const supplierData = SuppliersList?.data?.data.find(
      (sup) => sup.id === supplier.supplierId
    );
    return {
      ...supplierData,
      invoices: {
        amount: 0,
        totalPrice: Number(supplier?.totalSum?.amount),
        totalInvoices: Number(supplier?.invoiceCount),
      },
      supplierList: [supplierData?.id],
    };
  });
  const filteredList = endlist?.reduce((acc: any, cur: any) => {
    if (endlist.length > 4) {
      if (acc.length === 4) {
        let otherEl = acc[3];
        otherEl.displayName = `Others (${endlist.length - 3})`;
        otherEl.invoices = {
          amount: otherEl?.invoices?.amount + cur?.invoices?.amount,
          totalPrice: otherEl?.invoices?.totalPrice + cur?.invoices?.totalPrice,
          totalInvoices:
            otherEl?.invoices?.totalInvoices + cur?.invoices?.totalInvoices,
        };
        otherEl.supplierList.push(cur?.id);
      } else {
        acc.push(cur);
      }
    } else {
      acc.push(cur);
    }

    return acc;
  }, []);
  return filteredList?.length > 0 ? (
    <Box sx={{ height: "100%" }}>
      <CostBreakdownComponent
        isLoading={SupplierReport.isLoading}
        invoicedSuppliers={filteredList ?? []}
      />
    </Box>
  ) : null;
};

export default CostBreakdownWrapper;
