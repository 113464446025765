import React, { useEffect } from "react";
import { SearchRounded } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { InputAdornment, TextField, useMediaQuery } from "@mui/material";

interface Props {
  onQueryChange: (query: string) => void;
  autoFocus?: boolean;
  fullWidth?: boolean;
  variant?: "outlined" | "standard" | "filled";
  otherVariant?: "fullClear";
  placeholder?: string;
  initialSearchKey?: string;
  onKeypress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const StyledTextField = styled(TextField)<{
  otherVariant?: string;
}>(({ otherVariant }) =>
  otherVariant === "fullClear"
    ? {
        "& .MuiInputBase-root::before": {
          content: "none",
        },
        "& .MuiInputBase-root::after": {
          content: "none",
        },
      }
    : {}
);

export const TextSearch = ({
  onQueryChange,
  autoFocus,
  fullWidth,
  otherVariant,
  variant,
  placeholder,
  initialSearchKey,
  onKeypress,
}: Props) => {
  const isScaled = useMediaQuery(
    "(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)"
  );
  const { t } = useTranslation();
  useEffect(() => {
    return () => {
      onQueryChange("");
    };
  }, []);

  return (
    <StyledTextField
      value={initialSearchKey}
      variant={variant || "outlined"}
      otherVariant={otherVariant}
      placeholder={placeholder || t("search_placeholder")}
      onChange={(e) => onQueryChange(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchRounded style={{ color: "#6B7280", fontSize: "2rem" }} />
          </InputAdornment>
        ),
        sx: {
          input: {
            fontSize: "1.6rem",
            paddingTop: "1.6rem",
            paddingBottom: "1.6rem",
          },
        },
        onKeyPress: onKeypress,
      }}
      autoFocus={autoFocus}
      style={{
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 0,
        paddingBottom: 0,
        width: fullWidth ? "100%" : isScaled ? 180 : 220,
        // height: isScaled ? 24 : 40,
        flex: fullWidth ? 1 : "0 1 auto",
      }}
    />
  );
};
