import { Avatar, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LessMoreIcon from "@mui/icons-material/ExpandLess";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  //TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useRouter } from "next/router";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { apiRequestHandler } from "../../api/requestHandler";
import { IReduxState } from "../../redux/app_store";
import { useHashFragment } from "../../hooks/use-hash-fragment";
import { ImageApp } from "../share/image-app";
import { Scrollbar } from "../share/scrollbar";
import CurrencyFormat, { currencyFormatMap } from "../../utils/currency-format";
import { ArrowRight as ArrowRightIcon } from "../../icons/arrow-right";
import { ConfirmationAlertPopover } from "../price-alerts/confirmation-alert-popover";
import { Grid } from "@material-ui/core";
import { CircularLoader } from "../share/circular-loader";
interface Item {
  [key: string]: any;
}

const PriceAlertElement: FC<Item> = ({ iKey, alert, isOpen }) => {
  const [isExpanded, setIsExpanded] = useState(isOpen || false);
  const router = useRouter();
  const { selectedUnit, selectedOutlet, user } = useSelector(
    (store: IReduxState) => store
  );
  const userOutletRole = user?.units?.find(
    (outlet) => outlet.id === selectedUnit?.id
  )?.role;
  const { t } = useTranslation();
  const { hash } = window.location;
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  let currentHash = hash ? hash?.replace("#", "") : "";
  currentHash !== "" && useHashFragment(`invoice-row-${currentHash}`);
  const [confirmationTo, setConfirmationTo] = useState("");

  const [selectedDeviation, setSelectedDeviation] = useState<any>(null);
  const [alertItems, setAlertItems] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>(false);

  const handleOpenPopover = (deviation): void => {
    setSelectedDeviation(deviation);
    setOpenPopover(true);
  };

  const handleClosePopover = (): void => {
    setSelectedDeviation(null);

    setOpenPopover(false);
  };

  const fetchItems = async () => {
    const resp: any = await apiRequestHandler(
      `/api/hospitad/clouseau/deviations/outlets/${selectedOutlet?.id}/items?pageSize=${alert.total}&articleId=${alert.id}`,
      "GET",
      {},
      {
        "x-unit-id": selectedUnit?.id,
        "Content-Type": "application/json",
      }
    );
    return resp?.content;
  };

  const handleChange = () => {
    if (!isExpanded && alertItems.length == 0) {
      setIsLoading(true);
      fetchItems()
        .then((res) => {
          setAlertItems(res);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Accordion
      expanded={isExpanded}
      key={iKey}
      sx={{
        height: "fit-content",
        width: "100%",
        cursor: "unset",
        backgroundColor: isExpanded ? "rgba(41, 167, 118, 0.1)" : "transparent",
        mb: "1px",
        "&:before": {
          display: "none",
        },
        "&.Mui-expanded": {
          margin: "auto auto 1px !important",
          borderRadius: 0,
        },
      }}
      onChange={handleChange}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        key={`summary-${iKey}`}
        style={{ width: "100%" }}
        sx={{
          display: "flex",
          position: "relative",
          justifyContent: "space-between",
          height: "7.6rem",
          flexDirection: "row",
          ".Mui-expanded": {
            margin: "auto !important",
          },
          cursor: "unset !important",
          /* "&.Mui-expanded": {
            minHeight: "48px",
          }, */
          "@media (max-width: 835px)": {
            height: "11rem",
          },
        }}
      >
        <Box sx={{ width: "100%", cursor: "unset" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                textDecoration: "none",
                whiteSpace: "nowrap",
                width: "100%",
              }}
            >
              <Avatar
                sx={{
                  height: 52,
                  width: 52,
                  backgroundColor: "transparent",
                }}
              >
                <ImageApp
                  style={{
                    maxWidth: "4.2rem",
                    maxHeight: "4.2rem",
                    borderRadius: "30rem",
                  }}
                  mainImage={`https://cdn.parsly.com/images/suppliers/${alert?.supplierId}S.png`}
                  defaultImg="/img/icons/pwa-192x192.png"
                  brokenImg="/img/icons/pwa-192x192.png"
                />
              </Avatar>
              <Box
                sx={{
                  flex: "1",
                  fontSize: "1.4rem",
                  ml: "1.6rem",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "left",
                  flexDirection: "row",
                  "@media (max-width: 835px)": {
                    flexDirection: "column",
                    alignItems: "flex-start",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle1"
                    fontWeight={500}
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      router.push(`/sourcing/articles/${alert.id}`)
                    }
                  >
                    {alert?.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#6B7280",
                      display: "flex",
                      alignItems: "center",
                    }}
                    variant="subtitle2"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        background: "#FE164A",
                        height: "0.8rem",
                        width: "0.8rem",
                        borderRadius: "50%",
                        mr: "0.5rem",
                      }}
                    />
                    <Box sx={{ color: "#FE164A", mr: "0.5rem" }}>{`${
                      alert?.total
                    } ${t(
                      "account_page__notifications__alerts__label"
                    )} `}</Box>
                    {`${t(
                      "account_page__notifications__alerts__description"
                    )} `}

                    <Typography
                      variant="body2"
                      sx={{
                        color: "#6B7280",
                        display: "flex",
                        alignItems: "center",
                        mx: "0.5rem",
                        fontWeight: 700,
                      }}
                    >
                      {CurrencyFormat(
                        Number(alert?.latest?.billedPrice?.amount).toString(),
                        currencyFormatMap[selectedOutlet?.countryId!]
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mr: "1rem", color: "#FE164A" }}
                    >
                      (+
                      {CurrencyFormat(
                        Number(alert?.latest?.itemDiff?.amount).toString(),
                        currencyFormatMap[selectedOutlet?.countryId!]
                      )}
                      )
                    </Typography>
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    "@media (max-width: 835px)": {
                      justifyContent: "space-between",
                      width: "100%",
                      mt: "1rem",
                    },
                  }}
                  ref={anchorRef}
                >
                  {userOutletRole !== "REPORTER" && (
                    <Box>
                      <Button
                        color="primary"
                        variant="text"
                        onClick={() => {
                          setConfirmationTo("ignore");
                          handleOpenPopover(alert);
                        }}
                      >
                        {t(
                          "account_page__notifications__alerts__modals__resolve__ignore_button"
                        )}
                      </Button>

                      <Button
                        type="submit"
                        variant="outlined"
                        sx={{ mx: 2 }}
                        onClick={() => {
                          setConfirmationTo("resolve");
                          handleOpenPopover(alert);
                        }}
                      >
                        {t(
                          "account_page__notifications__alerts__modals__resolve__resolve_button"
                        )}
                      </Button>
                    </Box>
                  )}

                  <Button
                    onClick={() => setIsExpanded(!isExpanded)}
                    sx={{
                      borderRadius: "2em",
                      border: 0,
                      justifyContent: "center",
                      display: "flex",
                      padding: "1rem",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#eee",
                      },
                    }}
                    //variant="text"
                  >
                    {!isExpanded ? (
                      <ExpandMoreIcon fontSize="large" />
                    ) : (
                      <LessMoreIcon fontSize="large" />
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{ backgroundColor: "#FFF", width: "100%", p: 0, overflowX: "auto" }}
        key={`details-${iKey}`}
      >
        <Scrollbar sx={{ maxHeight: "31rem" }}>
          <Table>
            <TableBody>
              {isLoading ? (
                <CircularLoader />
              ) : (
                alertItems.map((item, idx) => (
                  <TableRow
                    hover
                    sx={{ cursor: "pointer" }}
                    key={`${item.id}-${idx}`}
                    onClick={() =>
                      router.push(`/invoice/${item.invoiceId}#${item.itemId}`)
                    }
                  >
                    <TableCell
                      sx={{
                        textAlign: "right",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={9}>
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                            }}
                          >
                            <Typography
                              sx={{ color: "#6B7280", mr: "0.5rem" }}
                              variant="subtitle2"
                            >
                              {item.invoiceDate}
                            </Typography>
                            <Typography variant="subtitle2">
                              {alert?.title}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box
                            sx={{
                              textAlign: "right",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ mr: "1rem", color: "#6B7280" }}
                            >
                              {CurrencyFormat(
                                Number(
                                  alert?.latest?.billedPrice?.amount
                                ).toString(),
                                currencyFormatMap[selectedOutlet?.countryId!]
                              )}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ mr: "1rem", color: "#FE164A" }}
                            >
                              (+
                              {CurrencyFormat(
                                Number(item.itemDiff.amount).toString(),
                                currencyFormatMap[selectedOutlet?.countryId!]
                              )}
                              )
                            </Typography>
                            <Box
                              className="invoice-actions"
                              style={{
                                textAlign: "end",
                                marginTop: "auto",
                                marginBottom: "auto",
                                display: "flex",
                              }}
                            >
                              <ArrowRightIcon
                                style={{
                                  width: "3rem",
                                  height: "2.6rem",
                                  color: "#6B7280",
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Scrollbar>
      </AccordionDetails>
      {openPopover && (
        <ConfirmationAlertPopover
          anchorEl={anchorRef.current}
          onClose={handleClosePopover}
          dealDetails={selectedDeviation}
          confirmationTo={confirmationTo}
          setConfirmationTo={setConfirmationTo}
          open={openPopover}
        />
      )}
    </Accordion>
  );
};
export default PriceAlertElement;
