import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import { useQuery } from "react-query";
import { IReduxState } from "../../redux/app_store";
import { useSelector } from "react-redux";
import { apiRequestHandler } from "../../api/requestHandler";
import { CircularLoader } from "../share/circular-loader";
import { useRouter } from "next/router";
import { Scrollbar } from "../share/scrollbar";
import { subYears, format } from "date-fns/fp";
import PriceAlertElement from "./overview-price-alert-element";
import { useState, useEffect } from "react";
import AllAlertsDone from "../../../public/img/all-alerts-done-illustration.svg";
const OverviewPriceAlert: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const router = useRouter();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { selectedOutlet, selectedUnit } = useSelector(
    (store: IReduxState) => store
  );

  const summaryResponse: any = useQuery(
    [selectedOutlet?.id, router],
    async () => {
      const data: any = await apiRequestHandler(
        `/api/general/price-alert`,
        "POST",
        {
          outletId: selectedOutlet?.id,
          fromDate: selectedOutlet?.firstRecordAt
            ? selectedOutlet?.firstRecordAt
            : format("yyyy-MM-dd", new Date(subYears(1, Date.now()))),
          toDate: format("yyyy-MM-dd", new Date(Date.now())),
          page: page,
          pageSize: rowsPerPage,
        },
        {
          "x-unit-id": selectedUnit?.id,
          "Content-Type": "application/json",
        }
      );

      return data;
    },
    {
      enabled: !!selectedOutlet?.id,
      retry: 0,
    }
  ) ?? { data: { priceAlert: [], total: 0 } };

  useEffect(() => {
    summaryResponse?.refetch();
  }, [page, rowsPerPage]);

  let alerts = summaryResponse?.data?.priceAlert;
  let total = summaryResponse?.data?.total;

  return (
    <Box
      sx={{
        padding: "0",
        marginTop: "3.2rem",
        flex: 1,
        maxWidth: "100%",
        background: "white",
        minWidth: "33rem",
        borderRadius: ".8rem",
        height: "calc(100% - 3.2rem)",
        "@media (min-width: 426px)": {
          padding: "0",
        },
      }}
    >
      <Box
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          p: "1.4rem 2.4rem",
        }}
      >
        <Grid
          container
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Grid item>
            <Typography variant="overline">
              {t("overview_page__price_alert__title")}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              lineHeight: "0.5",
            }}
          ></Grid>
        </Grid>
      </Box>
      <Box sx={{ p: "0", maxWidth: "100%" }}>
        <Box
          sx={{
            //pb: "2.4rem",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              flex: 1,
              //marginTop: "3.2rem",
              minWidth: "25rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "100%" }}>
              {summaryResponse?.isLoading ? (
                <CircularLoader />
              ) : (
                <Scrollbar>
                  {alerts?.length === 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        my: 3,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ mr: "3.2rem" }}>
                          <AllAlertsDone />
                        </Box>
                        <Box>
                          <Typography color="primary" variant="h5">
                            {t(
                              "account_page__notifications__alerts__price_alert_all_done_title"
                            )}
                          </Typography>
                          <Typography>
                            {t(
                              "account_page__notifications__alerts__price_alert_all_done_description"
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    alerts?.map((alert) => (
                      <PriceAlertElement
                        key={`alert-id-${alert.id}`}
                        iKey={alert.id}
                        alert={alert}
                        isOpen={false}
                      />
                    ))
                  )}
                </Scrollbar>
              )}
            </Box>
          </Box>
        </Box>
        {alerts?.length > 0 && (
          <Box>
            <TablePagination
              labelRowsPerPage={t(
                "application_utils__table__rows_per_page_label"
              )}
              component="div"
              count={summaryResponse?.isLoading ? 0 : Number(total)}
              onPageChange={(_, page) => {
                setPage(page + 1);
              }}
              onRowsPerPageChange={(e: any) => {
                setRowsPerPage(Number(e?.target?.value!));
                setPage(1);
              }}
              page={
                summaryResponse?.isLoading
                  ? 0
                  : Number(page > 0 ? page - 1 : page)
              }
              rowsPerPage={Number(rowsPerPage)}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OverviewPriceAlert;
