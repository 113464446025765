import { useTranslation } from "react-i18next";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import { useQuery } from "react-query";
import { IReduxState } from "../../redux/app_store";
import { useSelector } from "react-redux";
import { apiRequestHandler } from "../../api/requestHandler";
import CurrencyFormat, { currencyFormatMap } from "../../utils/currency-format";
import { CircularLoader } from "../share/circular-loader";
import { ImageApp } from "../share/image-app";
import { useRouter } from "next/router";
import NextLink from "next/link";
import { BadgeCheck } from "../../icons/badge-check";

const OverviewCostSummary: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const router = useRouter();

  const currentDate = new Date();
  const previousMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    currentDate.getDate(),
    0,
    0,
    0,
    0
  );

  const { selectedOutlet, selectedUnit } = useSelector(
    (store: IReduxState) => store
  );

  const summaryResponse: any = useQuery(
    [selectedOutlet?.id],
    async () => {
      const data: any = await apiRequestHandler(
        `/api/general/main-summary`,
        "POST",
        {
          outletId: selectedOutlet?.id,
          fromDate: previousMonth.toISOString(),
          toDate: currentDate.toISOString(),
        },
        {
          "x-unit-id": selectedUnit?.id,
          "Content-Type": "application/json",
        }
      );

      return data;
    },
    { enabled: !!selectedOutlet?.id, retry: 0 }
  ) ?? { data: {} };

  return !!summaryResponse?.data?.summary?.totalSum ? (
    <Box
      sx={{
        padding: "0",
        marginTop: "3.2rem",
        flex: 1,
        maxWidth: "100%",
        background: "white",
        //minWidth: "33rem",
        borderRadius: ".8rem",
        height: "calc(100% - 3.2rem)",
        "@media (min-width: 426px)": {
          padding: "0",
        },
      }}
    >
      <Box
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          p: "1.4rem 2.4rem",
        }}
      >
        <Typography variant="overline">
          {t("overview_page__summary__title")}
        </Typography>
      </Box>
      {summaryResponse.isLoading ? (
        <CircularLoader />
      ) : !!summaryResponse?.data?.summary?.totalSum ? (
        <>
          <Box
            sx={{
              px: "2.4rem",
              py: 3,
              maxWidth: "100%",
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box
              sx={{
                //pb: "2.4rem",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  //marginTop: "3.2rem",
                  minWidth: "25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      //mr: 9,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <NextLink
                      href={"/articles/products?fromDate=date__month" as string}
                      passHref
                    >
                      <Typography
                        color="primary"
                        variant="h4"
                        sx={{ fontWeight: 700, cursor: "pointer" }}
                      >
                        {CurrencyFormat(
                          Number(
                            summaryResponse?.data?.summary?.totalSum?.amount
                          ).toString(),
                          currencyFormatMap[selectedOutlet?.countryId!]
                        )}
                      </Typography>
                    </NextLink>

                    <Typography
                      color="textSecondary"
                      variant="subtitle2"
                      sx={{ fontWeight: 600 }}
                    >
                      {`${t(
                        "overview_page__summary__summary_compare"
                      )} ${CurrencyFormat(
                        Number(
                          summaryResponse?.data?.summary?.monthly[0]?.sum
                            ?.amount
                        ).toString(),
                        currencyFormatMap[selectedOutlet?.countryId!]
                      )} ${t(
                        "overview_page__summary__summary_compare_description"
                      )}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              px: "2.4rem",
              py: 3,
              maxWidth: "100%",
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box
              sx={{
                //pb: "2.4rem",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  //marginTop: "3.2rem",
                  minWidth: "25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      //mr: { xs: 0, sm: 9 },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",

                      cursor: "pointer",
                    }}
                    onClick={() => router.push("/invoices")}
                  >
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "inline-flex",
                        textDecoration: "none",
                        whiteSpace: "nowrap",
                        width: "100%",
                      }}
                    >
                      <Avatar
                        sx={{
                          height: 52,
                          width: 52,
                          backgroundColor: "transparent",
                        }}
                      >
                        <ImageApp
                          style={{
                            maxWidth: "4.2rem",
                            maxHeight: "4.2rem",
                            borderRadius: "30rem",
                          }}
                          mainImage={`https://cdn.parsly.com/images/suppliers/${summaryResponse?.data?.supplier?.id}S.png`}
                          defaultImg="/img/icons/pwa-192x192.png"
                          brokenImg="/img/icons/pwa-192x192.png"
                        />
                      </Avatar>
                      <Box
                        sx={{
                          flex: "1",
                          fontSize: "1.4rem",
                          ml: "1.6rem",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography color="textPrimary" variant="subtitle1">
                            {t(
                              "overview_page__summary__summary_last_invoice_prefix"
                            )}
                            {summaryResponse?.data?.lastInvoice?.number}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="caption"
                            textAlign="right"
                          >
                            {summaryResponse?.data?.lastInvoice?.date}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography
                            sx={{ color: "#6B7280" }}
                            variant="subtitle2"
                          >
                            {summaryResponse?.data?.supplier?.displayName}
                          </Typography>
                          <Typography
                            sx={{ color: "#6B7280" }}
                            variant="caption"
                            textAlign="right"
                          >
                            {t(
                              "overview_page__summary__summary_last_invoice_description"
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              px: "2.4rem",
              py: 3,
              maxWidth: "100%",
            }}
          >
            <Box
              sx={{
                //pb: "2.4rem",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  //marginTop: "3.2rem",
                  minWidth: "25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      //mr: { xs: 0, sm: 9 },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "inline-flex",
                        textDecoration: "none",
                        whiteSpace: "nowrap",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          flex: "1",
                          fontSize: "1.4rem",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography
                            sx={{ color: "#6B7280" }}
                            variant="subtitle2"
                          >
                            {t("overview_page__summary__summary_alerts_title")}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ color: "#29A776" }}
                              variant="h4"
                              textAlign="right"
                            >
                              {summaryResponse?.data?.alerts}
                            </Typography>
                            <BadgeCheck
                              sx={{
                                width: "3.2rem",
                                height: "3.2rem",
                                ml: 1,
                                backgroundColor: "primary",
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <h1>No data</h1>
        </>
      )}
    </Box>
  ) : null;
};

export default OverviewCostSummary;
