import * as React from "react";
import {
  Checkbox,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
  InputLabel,
} from "@mui/material";

export interface IOptionItem<T> {
  label: React.ReactElement | string;
  key?: string;
  option: T;
  value: string;
  checked?: boolean;
}

interface IProps<T> extends SelectProps<T> {
  options?: Array<IOptionItem<T>>;
  value?: T;
  handleSelect?(option: T, key?: string): void;
  getOptionDisabled?(option: T): boolean;
  multiple?: boolean;
}

export function Selector<T>(props: IProps<T>) {
  const selectFieldOriginProps = (selectorProps: IProps<T>): SelectProps<T> => {
    let selectFieldProps: IProps<T> = { ...selectorProps };
    delete selectFieldProps.options;
    // delete selectFieldProps.onSelect;
    delete selectFieldProps.handleSelect;
    delete selectFieldProps.sx;

    return selectFieldProps;
  };
  return (
    <>
      <FormControl sx={props.sx}>
        <InputLabel id={props.labelId}>{props.label}</InputLabel>
        <Select
          size="medium"
          labelId={props.labelId}
          input={<OutlinedInput label={props.label} />}
          sx={{
            "& .MuiSelect-icon": {
              fontSize: "2.4rem",
            },
          }}
          {...selectFieldOriginProps(props)}
        >
          {props.options?.map((option) => (
            <MenuItem
              key={option.key}
              disabled={props.getOptionDisabled?.(option.option)}
              onClick={() => {
                props.handleSelect?.(option.option, option.key);
              }}
              value={option.value}
            >
              {props.multiple ? <Checkbox checked={option.checked} /> : null}
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
