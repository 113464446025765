import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import { Chart } from "../../../components/chart";
import { ApexOptions } from "apexcharts";
import { graph } from "../../../theme/colors";
import { IExtendedSuppliersWithInvoices } from "../../../components/sourcing/popular-suppliers/tableColumns";
import { useRouter } from "next/router";
import { CircularLoader } from "../../../components/share/circular-loader";

const CostBreakdownComponent: React.FC<{
  invoicedSuppliers: IExtendedSuppliersWithInvoices[];
  isLoading: boolean;
}> = ({ invoicedSuppliers, isLoading }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const router = useRouter();

  const labels = invoicedSuppliers.map((inv) => inv?.displayName ?? "");
  const options: ApexOptions = {
    chart: {
      background: "transparent",
      //width: "120rem",
      height: 600,

      type: "radialBar",
      /* events: {
        dataPointMouseEnter: function (event, chartContext, config) {
          // ...
          //console.log("updated2 ", event, chartContext, config);
        },
        dataPointSelection(event, chartContext, config) {
          //console.log("test", config);
        },
      } */
    },
    labels: labels,
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          show: false,
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
            formatter: function (val) {
              //console.log("val", val);
              return val + "%";
            },
          },
          /* total: {
            show: true,
            label: "Total",
            formatter: function (w: any) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function

              return total;
            },
          }, */
          /* fill: {
            colors: undefined,
            opacity: 0.9,
            type: 'solid',
            gradient: {
                shade: 'dark',
                type: "horizontal",
                shadeIntensity: 0.5,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 100],
                colorStops: []
            },
            image: {
                src: [],
                width: undefined,
                height: undefined
            },
            pattern: {
                style: 'verticalLines',
                width: 6,
                height: 6,
                strokeWidth: 2,
            },
          } */
        },
      },
    },
    colors: graph,
    /* stroke: {
      width: 0,
    }, */
    /* tooltip: {
      enabled: false,
      fillSeriesColor: false,
      y: {
        formatter: function (val: any) {
          console.log("val", val)
          return val;
        },
        title: {
          formatter: function (e) {
            console.log("e", e)
            return e;
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    }, */

    /* responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
        },
      },
    ], */
  };

  const series = useMemo(
    () => invoicedSuppliers?.map((inv) => inv?.invoices?.totalPrice ?? 0) ?? [],
    [invoicedSuppliers]
  );

  const total = Number(series.reduce((a, b) => a + b, 0).toFixed(2));
  const seriesPercent = series.map((s) => ({
    value: s,
    percent: Math.round((s / total) * 100),
  }));
  /* const { colorBullet, supplierNameWithLogo, invoicesPercent } =
    PopularSupplierColDefs({
      suppliers: invoicedSuppliers,
      sortColumn: "",
    }); */
  /* const columns = [colorBullet, supplierNameWithLogo, invoicesPercent]; */

  const endList: any = invoicedSuppliers.map((el) => {
    return {
      ...el,
      percent: Math.round(((el?.invoices?.totalPrice as number) / total) * 100),
    };
  });

  if (endList.length === 4 && endList[3].supplierList.length > 1) {
    endList
      .filter((el) => el.supplierList.length === 1)
      .sort((a, b) => b.percent - a.percent)
      .push(endList[3]);
  } else {
    endList.sort((a, b) => b.percent - a.percent);
  }
  return (
    <Box
      sx={{
        padding: "0",
        marginTop: "3.2rem",
        flex: 1,
        maxWidth: "100%",
        background: "white",
        borderRadius: "0.8rem",
        height: "calc(100% - 3.2rem)",
        /* "@media (min-width: 426px)": {
          marginLeft: "1.6rem",
          marginRight: "1.6rem",
          padding: "0",
          borderRadius: ".8rem",
        }, */
      }}
    >
      <Box
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          p: "1.4rem 2.4rem 1.4rem",
        }}
      >
        <Typography variant="overline">
          {t("price-monitoring__cost-breakdown-title")}
        </Typography>
      </Box>
      <Box
        sx={{
          p: "1rem 2.4rem",
          maxWidth: "100%",
          height: "calc(100% - 5.9rem)",
          display: "flex",
        }}
      >
        <Box
          sx={{
            //pb: "2.4rem",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {isLoading ? (
            <CircularLoader />
          ) : (
            <>
              {invoicedSuppliers.length > 0 ? (
                <Box
                  sx={{
                    flex: 1,
                    //marginTop: "3.2rem",
                    minWidth: "25rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Chart
                    type="donut"
                    options={options}
                    series={seriesPercent.map((el) => el.percent) ?? []}
                  />
                </Box>
              ) : (
                <h1>No data</h1>
              )}
              <Box
                sx={{
                  flex: 1,
                  //marginTop: "3.2rem",
                  minWidth: "25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  {/* <TableApp
                rows={endList}
                columns={columns}
                autoHeight
                rowCount={1}
                page={1}
                pageSize={10}
                setPageSize={() => {}}
                setPage={() => {}}
                rowHeight={52}
                headerHeight={0}
                withoutFooter
              /> */}
                  {/* <Scrollbar> */}
                  <Table>
                    <TableBody>
                      {endList.map((item, idx) => (
                        <TableRow
                          key={item.id}
                          hover
                          onClick={() =>
                            router.push(
                              `/articles/products?fromDate=date__month&supplier=${item?.supplierList.join(
                                "&supplier="
                              )}&sortBy=article__ASC&page=1&pageSize=10`
                            )
                          }
                          sx={{
                            cursor: "pointer",
                            "&:last-child": {
                              td: {
                                borderBottom: 0,
                              },
                            },
                          }}
                        >
                          <TableCell>
                            <Box
                              sx={{
                                maxWidth: "1.3rem",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  border: `3px solid ${graph[idx]}`,
                                  padding: "0.5rem",
                                  borderRadius: "3rem",
                                }}
                              />
                            </Box>
                          </TableCell>
                          <TableCell>{item.displayName}</TableCell>
                          <TableCell
                            sx={{ textAlign: "right" }}
                          >{`${item.percent}%`}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {/* </Scrollbar> */}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CostBreakdownComponent;
