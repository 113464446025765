import { useState } from "react";
import { useRouter } from "next/router";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import { TextSearch } from "../../components/SearchField";

const SearchArticlesComponent: React.FC<{}> = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  return (
    <Box
      sx={{
        margin: "3.2rem 0 0",
        background: "white",
        padding: "1.4rem 2.4rem",
        flex: 1,
        minWidth: "100%",
        borderRadius: "0.8rem",

        "@media (min-width: 426px)": {
          minWidth: "35rem",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "-1.6rem",
          marginRight: "-1.6rem",
          //marginTop: "1.6rem",
          flexWrap: "wrap",
          alignItems: "center",

          "@media (min-width: 426px)": {
            marginRight: "0",
          },
        }}
      >
        <TextSearch
          variant="outlined"
          fullWidth
          placeholder={t("search_placeholder")}
          initialSearchKey={search}
          onQueryChange={(query) => {
            setSearch(query);
          }}
          onKeypress={(e) => {
            if (e.key === "Enter" && search.length > 1) {
              e.preventDefault();
              router.push(
                `/sourcing/articles/search?from=overview&s=${search}`
              );
            }
          }}
        />
        <Button
          variant="contained"
          onClick={() =>
            router.push(`/sourcing/articles/search?from=overview&s=${search}`)
          }
          disabled={search.length < 2}
          sx={{
            padding: "0 2.4rem",
            margin: "3.2rem 1.6rem 0",
            height: "4rem",
            width: "100%",

            "@media (min-width: 426px)": {
              width: "auto",
              margin: "0",
            },
          }}
        >
          {t("search")}
        </Button>
      </Box>
    </Box>
  );
};

export default SearchArticlesComponent;
